
import { Options, Vue } from 'vue-class-component';
import { apiReportGetListTotalNum,
         apiReportGetList } from '@/services/tpsApi/tpsApiReport';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '@/services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '@/services/exportFile';
import { 
    IReportGetListTotalNumRequestParams,
    IReportGetListRequestParams
 } from '@/services/tpsApi/models/IReport';
import ReportChiayiSearchMenu from '@/components/ReportChiayiSearchMenu.vue';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    ReportChiayiSearchMenu,
    Table,
    Pagination
  },
})
export default class ReportChiayi extends Vue {
    TABLE_HEAD: {} = {
        "交易日期": "交易日期",
        "交易時間": "交易時間",
        "卡號": "卡號",
        "發卡公司": "發卡公司",
        "卡別": "卡別",
        "身分別": "身分別",
        "駕駛員帳號": "駕駛員帳號",
        "車號": "車號",
        "清分狀態": "清分狀態",
        "清分日期": "清分日期",
        "使用點數": "使用點數",
        "使用錢包": "使用錢包",
        "實收金額": "實收金額",
        "應收金額": "應收金額"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    reportList: [] | Blob = [];
    REPORT = "2"; //report="2"，表示產生第二種業者報表，愛心卡點數請款明細表
    pageNo: {} = PAGENO;
    searchQuery: {} = {};

    mounted() {
        this.refreshTabel({report: this.REPORT, limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination(pQuery?: IReportGetListTotalNumRequestParams) {
        let query = Object.assign({report: this.REPORT}, this.searchQuery);
        this.pageTotalNum = await apiReportGetListTotalNum(query);       
    }

    async refreshTabel(pData?: IReportGetListRequestParams) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        if(pData && !pData.report) pData.report = this.REPORT;
        pData = Object.assign(pData, this.searchQuery);
        this.reportList = await apiReportGetList(pData);
    }

    async download(pData: { output: string }) {
        const res = await apiReportGetList(Object.assign({report: this.REPORT, 
                                                          output: pData.output},
                                                          this.searchQuery));
        exportFile({ fileName: "嘉義復康巴士請款明細表", data: res });
    }

    doSearch(pData) {
        this.searchQuery = Object.assign({}, pData);
        this.initPagination();
        this.refreshTabel({report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
    }
}
