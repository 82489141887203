
import { Options, Vue } from 'vue-class-component';
import { Emit } from "vue-property-decorator";
import { apiListMenuGetItem } from '../services/tpsApi/tpsApiListMenuItem';
import DatepickerLite from "vue3-datepicker-lite";

@Options({
  components: {
    DatepickerLite
  },
  emits: ["search"]
})


export default class ReportChiayiSearchMenu extends Vue {
    formData: {} = {};
    REPORT = "2"; //report="2"，表示產生第二種業者報表，愛心卡點數請款明細表

    cardCorpSelectionOptions: {index: string, text: string} | {} = {}; //發卡公司
    cardtypeSelectOptions: {index: string, text: string} | {} = {}; //卡別
    calStatusSelectOptions: {index: string, text: string} | {} = {}; //清分狀態 
    offStatusSelectOptions: {index: string, text: string} | {} = {}; //身份別

    txDateWarningText = "";
    dlDateWarningText = "";

    //Date picker setting
    startdate_tx = {
        id: "startdate_tx",
        class: "md-input myDateInput md-datepicker w-10-rem",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["startdate_tx"] = value;
          this.dateCheck("tx");
        }
    }

    enddate_tx = {
        id: "enddate_tx",
        class: "md-input myDateInput md-datepicker w-10-rem",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["enddate_tx"] = value;
          this.dateCheck("tx");
        }
    }

    startdate_dl = {
        id: "startdate_dl",
        class: "md-input myDateInput md-datepicker w-10-rem",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["startdate_dl"] = value;
          this.dateCheck("dl");
        }
    }

    enddate_dl = {
        id: "enddate_dl",
        class: "md-input myDateInput md-datepicker w-10-rem",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["enddate_dl"] = value;
          this.dateCheck("dl");
        }
    }

    datepickerActive(pID) {
        let datepicker = document.getElementById(pID)
        if(datepicker) (datepicker as HTMLFormElement).focus(); 
    }

    //Check if start date less than end date 
    dateCheck(dateColumn) {
        var start;
        var end;

        if(dateColumn == "tx" && this.formData["startdate_tx"] && this.formData["enddate_tx"]) {
            start = new Date(this.formData["startdate_tx"]);
            end = new Date(this.formData["enddate_tx"]);
            start>end?this.txDateWarningText="起始時間不能大於結束時間":this.txDateWarningText="";
        } 
        else if (dateColumn == "dl" && this.formData["startdate_dl"] && this.formData["enddate_dl"]) {
            start = new Date(this.formData["startdate_dl"]);
            end = new Date(this.formData["enddate_dl"]);
            start>end?this.dlDateWarningText="起始時間不能大於結束時間":this.dlDateWarningText="";
        }
    }

    async mounted() {
        //Select options setting
        this.cardCorpSelectionOptions = await apiListMenuGetItem({menuName: "交易明細表", listName: "發卡公司"});
        this.offStatusSelectOptions = await apiListMenuGetItem({menuName: "交易明細表", listName: "身份別"});
        this.calStatusSelectOptions = await apiListMenuGetItem({menuName: "交易明細表", listName: "清分狀態"});
    }

    async setCardtypeSelectOptions() {
        if(this.formData["ci"] != '') {
            this.cardtypeSelectOptions = await apiListMenuGetItem({ menuName: "交易明細表", 
                                                                    listName: "卡別",
                                                                    card_company: this.formData["ci"] });
        }
        this.formData["cardtype"] = null;
    }

    search() {
        let txStart = new Date(this.formData["startdate_tx"]);
        let txEnd = new Date(this.formData["enddate_tx"]);
        if(txStart > txEnd) {
            this.txDateWarningText="起始時間不能大於結束時間"
        }   
        else if (this.formData["startdate_tx"] && !this.formData["enddate_tx"] ||
            !this.formData["startdate_tx"] && this.formData["enddate_tx"]) {
            this.txDateWarningText = "請輸入完整起迄時間";
        }
        else {
            this.txDateWarningText = "";
        }

        let dlStart = new Date(this.formData["startdate_dl"]);
        let dlEnd = new Date(this.formData["enddate_dl"]);
        if(dlStart > dlEnd) {
            this.dlDateWarningText="起始時間不能大於結束時間"
        }  
        else if (this.formData["startdate_dl"] && !this.formData["enddate_dl"] ||
            !this.formData["startdate_dl"] && this.formData["enddate_dl"]) {
            this.dlDateWarningText = "請輸入完整起迄時間";
        }
        else {
            this.dlDateWarningText = "";
        }

        if(this.txDateWarningText == "" && this.dlDateWarningText == "") {
            let data = {
                startdate_tx: this.formData["startdate_tx"],
                enddate_tx: this.formData["enddate_tx"],
                card_id: this.formData["card_id"],
                ci: this.formData["ci"],
                cardtype: this.formData["cardtype"],
                off: this.formData["off"],
                an: this.formData["an"],
                plate: this.formData["plate"],
                cal_status: this.formData["cal_status"],
                startdate_dl: this.formData["startdate_dl"],
                enddate_dl: this.formData["enddate_dl"] 
            };

            this.doSearch(data);
        }
    }

    @Emit("search")
    doSearch(pData) {
        return pData;
    }
}
